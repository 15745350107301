export function convertIPFSLink(ipfsLink: string) {
  const ipfsGateway = "https://ipfs.io/ipfs/";

  // Trim any leading or trailing whitespace from the input
  const trimmedLink = ipfsLink.trim();

  const parts = trimmedLink.split("/ipfs/");
  if (parts.length === 2) {
    const hash = parts[1];
    const convertedLink = `${ipfsGateway}${hash}`;
    return convertedLink;
  } else {
    // Handle other cases here
    return trimmedLink;
  }
}

// export function convertIPFSLink(ipfsLink: string) {
//   const ipfsPrefix = "ipfs://";
//   const ipfsGateway = "https://ipfs.io/ipfs/";

//   // Trim any leading or trailing whitespace from the input
//   const trimmedLink = ipfsLink.trim();

//   if (trimmedLink.startsWith(ipfsPrefix)) {
//     const hash = trimmedLink.slice(ipfsPrefix.length);
//     const convertedLink = ipfsGateway + hash;
//     return convertedLink;
//   } else if (trimmedLink.includes("mypinata.cloud/ipfs/")) {
//     const parts = trimmedLink.split("mypinata.cloud/ipfs/");
//     if (parts.length === 2) {
//       const hash = parts[1];
//       const convertedLink = ipfsGateway + hash;
//       return convertedLink;
//     } else {
//       // Handle unexpected format for mypinata.cloud link
//       return trimmedLink;
//     }
//   } else {
//     // You might consider throwing an error or returning a clear message here for invalid input
//     return trimmedLink; // Return unchanged if not in expected format
//   }
// }
