import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  campaigns: null,
};
export const CampaignsSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    setCampaigns: (state, action) => {
      state.campaigns = action?.payload;
    },
  },
});
export const { setCampaigns } = CampaignsSlice.actions;

export default CampaignsSlice.reducer;
