import React from "react";
import styled from "styled-components";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

interface ConfirmDialogProps {
  open: boolean;
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  open,
  title,
  message,
  onCancel,
  onConfirm,
}) => {
  const RedButton = styled(Button)`
    && {
      background-color: red;
      color: white;
      &:hover {
        background-color: darkred;
      }
    }
  `;

  const GreenButton = styled(Button)`
    && {
      background-color: green;
      color: white;
      &:hover {
        background-color: darkgreen;
      }
    }
  }`;

  return (
    <Dialog open={open} onClose={onCancel} aria-labelledby="confirm-dialog">
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <GreenButton
          variant="contained"
          onClick={() => {
            onCancel();
            onConfirm();
          }}
          sx={{ backgroundColor: "green" }}
        >
          Yes
        </GreenButton>
        <RedButton
          variant="contained"
          onClick={onCancel}
          sx={{ backgroundColor: "red" }}
        >
          No
        </RedButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
