import React, { useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Tooltip } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener"; // Import ClickAwayListener

interface valuePropI {
  value: string;
}

const ShortenedHashWithCopy = ({ value }: valuePropI) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleCopyClick = () => {
    const textField = document.createElement("textarea");
    textField.innerText = value;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    setIsTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setIsTooltipOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        {value && value?.slice(0, 10) + "..."}
        <Tooltip
          title="Copy to clipboard"
          arrow
          open={isTooltipOpen}
          onClose={handleTooltipClose}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <span onClick={handleCopyClick}>
            <ContentCopyIcon style={{ cursor: "pointer", marginLeft: "5px" }} />
          </span>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

export default ShortenedHashWithCopy;
