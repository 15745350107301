import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  nftObj: undefined,
};

export const setNFTSlice = createSlice({
  name: "nfts",
  initialState,
  reducers: {
    setNFT: (state, action) => {
      state.nftObj = action?.payload?.data;
    },
  },
});
export const { setNFT } = setNFTSlice.actions;

export default setNFTSlice.reducer;
