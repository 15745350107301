import { Box, Typography } from "@mui/material";
import MaleCarouselComp from "../../BreedingScreenComp/MaleCarouselComp/MaleCarouselComp";
import NoNftFound from "../../../BredingComp/NoNftFound/NoNftFound";
import { useSelector } from "react-redux";
interface chooseMaleNftPropsI {
  isScreenLessThan1725px: boolean;
  areMaleNfts: boolean;
  nfts: any;
}

const ChooseMaleNft = ({
  isScreenLessThan1725px,
  areMaleNfts,
  nfts,
}: chooseMaleNftPropsI) => {
  const containerWidth = isScreenLessThan1725px ? "200px" : "500px";
  //   const containerWidth = "auto";
  const campaignsSelector = useSelector(
    (state: any) => state.campaigns.campaigns
  );

  const textFontSize = isScreenLessThan1725px ? 20 : 28;
  const maleCarouselWidth =
    nfts?.GroupA?.length || 1 > 2
      ? isScreenLessThan1725px
        ? 225
        : 325
      : "auto";

  return (
    <Box
      sx={{
        minWidth: containerWidth,
        boxShadow: "rgb(0 0 0 / 40%)1px 3px 40px 27px",
        background: "rgb(0 0 0 / 35%)",
        zIndex: 99,
      }}
      justifyContent="center"
      ml={isScreenLessThan1725px ? 2 : 20}
      mt={isScreenLessThan1725px ? -8 : -5}
    >
      <Box
        textAlign="center"
        sx={{
          background: "rgba(70, 70, 70, 0.8)",
          border: "solid 2px white",
          borderRadius: "10px",
          zIndex: 99,
          mb: isScreenLessThan1725px ? 0 : 12,
          flexDirection: "row",
          display: "flex",
          justifyContent: "center",
          // marginBlock: !isScreenLessThan1725px && campaignsSelector.campaignType === '2' ? -2 : 0,
        }}
        // the screen is probelm
      >
        <Typography
          sx={{
            fontSize: textFontSize,
            fontWeight: 600,
            color: "white",
            pr: 1,
            zIndex: 99,
          }}
        >
          CHOOSE A
        </Typography>
        <Typography
          sx={{
            fontSize: textFontSize,
            fontWeight: 600,
            color: "#FF0000",
            zIndex: 99,
          }}
        >
          NFT
        </Typography>
      </Box>
      {areMaleNfts ? (
        <Box
          width={maleCarouselWidth}
          sx={{
            boxShadow: "rgb(0 0 0 / 40%)1px 3px 40px 27px",
            background: "rgb(0 0 0 / 35%)",
            mt: isScreenLessThan1725px ? 1 : 2,
            ml: isScreenLessThan1725px ? 0 : 10,
            // marginBlock: !isScreenLessThan1725px && campaignsSelector.campaignType === '2' ? -2 : 0,

            // textAlign:"center"
          }}
        >
          <MaleCarouselComp />
        </Box>
      ) : (
        <NoNftFound gender="male" />
      )}
    </Box>
  );
};

export default ChooseMaleNft;
