import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  snackbarOpen: false,
  snackbarType: "success",
  snackbarMessage: "",
};
export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    setSnackbar: (state, action) => {
      return {
        ...state,
        snackbarOpen: action.payload.snackbarOpen,
        snackbarType: action.payload.snackbarType,
        snackbarMessage: action.payload.snackbarMessage,
      };
    },
  },
});

export const { setSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
