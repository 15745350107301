import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Typography,
  Modal,
  Button,
  IconButton,
  TextField,
} from "@mui/material";
import { useState, useEffect } from "react";
import DynamicTableView from "../Traits Components/DynamicTableView/DynamicTableView";
import { ToastContainer, toast } from "react-toastify";
import { Base_Url, axiosRequestLocal } from "../../api/api";
import { convertIPFSLink } from "../../HelperFunctions/HelperFunctions";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../shared/customTable/customTable";

import SearchIcon from "@mui/icons-material/Search";
interface Column {
  key: string;
  label: string;
  dataType?: "number" | "text" | "date"; // Add more data types if needed
}

const style = {
  borderRadius: "5px",
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const columns: Column[] = [
  { key: "userAccount", label: "Wallet Address", dataType: "text" },
  { key: "child", label: "Transaction #", dataType: "text" },
  { key: "parent", label: "User NFT", dataType: "text" },

  { key: "createdAt", label: "Created At", dataType: "date" },
];

const UsedNftsComp = () => {
  // use States
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const accountAddress = localStorage.getItem("accnum");

  //  Table View States
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [nftId, setNFTId] = useState<any>();
  // Confirm dialogue states
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<number>(0); // Add this state to track the selected item's ID for deletion
  const [searchDataValue, setSearchDataValue] = useState<any>();
  // UsefNfts
  const [usedNftsByUser, setUsedNftsByUser] = useState<any[]>([]);

  const [searchedData, setSearchedData] = useState<any>();

  //------------------- Functions ----------------------
  const getNFtsMinted = async () => {
    setLoading(true);
    try {
      const response: any = await axiosRequestLocal(
        "get",
        Base_Url + `/api/luxlion/nfts-minted`
      );
      if (response.data) {
        const nftsMinted = await response.data.nfts;
        console.log(nftsMinted, "nftsMinted");

        setUsedNftsByUser(nftsMinted);
      }
      setLoading(false);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  // Table View
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  //   Delete Prop
  const handleDelete = (id: number) => {
    setSelectedItemId(id);
    setConfirmOpen(true);
  };

  const handleEdit = (id: number) => {
    // Find the collection data with the given id
  };

  // delete the traitContent image
  const deleteUsedNfts = async (id: number) => {
    try {
      const response: any = await axiosRequestLocal(
        "post",
        Base_Url + `/api/luxlion/nft-delete/${id}`,
        {},
        {}
      );
      toast.success("Minted Nfts Successfully Deleted", { autoClose: 3000 });
      getNFtsMinted();
    } catch (error: any) {
      console.log("Error fetching data:", error);
      toast.error(error.response.data.message);
    }
  };
  const checkAdmin = async (accountAddress: string): Promise<void> => {
    try {
      if (accountAddress && accountAddress.length) {
        let URL = `${Base_Url}/api/admin/check/${accountAddress}`;
        const response: any = await axiosRequestLocal("get", URL);
        if (response.data && response.data.status) {
          navigate(`/admin-panel/usedNfts`);
        } else {
          navigate("/");
        }
      } else {
        navigate("/");
      }
    } catch (error) {
      navigate("/");
      console.log(error);
    }
  };

  // ------------------------------Use Effects---------------------------------

  useEffect(() => {
    // document.body.style.overflow = "auto";
    if (accountAddress) {
      checkAdmin(accountAddress);
    }
    // setTimeout(() => {
    setLoading(false);
    // }, 2000);
    getNFtsMinted();
    setLoading(true);
  }, []);

  const handleCloseModal = () => {
    setConfirmOpen(false);
  };
  const handleDeleteClick = (data: any) => {
    // Handle edit action for the selected user
    setNFTId(data.id);
    setConfirmOpen(true);
  };

  const handleDeleteClickYes = () => {
    // Handle edit action for the selected user
    deleteUsedNfts(nftId);
    setConfirmOpen(false);
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      const filteredData = searchUserAccountOrParent(
        usedNftsByUser,
        searchDataValue
      );
      setSearchedData(filteredData);
    }, 500); // 0.5 seconds debounce

    return () => clearTimeout(timerId);
  }, [usedNftsByUser, searchDataValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchDataValue(event.target.value.trim());
  };
  function searchUserAccountOrParent(
    dataArray: Array<any>,
    searchTerm: string
  ) {
    return dataArray.filter((obj: any) => {
      return obj.userAccount === searchTerm || obj.parent === searchTerm;
    });
  }

  return (
    <>
      <Container sx={{ borderRadius: 4, mt: 2 }}>
        <Paper elevation={4} sx={{ borderRadius: 4 }}>
          <Box
            padding={3}
            // borderRadius={5}
            sx={{
              borderRadius: 4,
              backgroundColor: "white",
            }}
          >
            <Box display="flex" justifyContent={"center"} pb={2}>
              <Typography variant="h4">Marked NFTS</Typography>
            </Box>

            <Box sx={{ p: 2 }} display="flex" justifyContent={"right"}>
              <TextField
                id="outlined-basic"
                label="Search"
                size="small"
                variant="outlined"
                value={searchDataValue}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      edge="end"
                      aria-label="search"
                      sx={{ p: "10px" }} // Optional: Adjust padding for icon
                    >
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
              />
            </Box>
            <Grid container spacing={2} sx={{ mt: "2px" }}>
              {/*---------------- Select Trait--------------- */}
            </Grid>

            {/* <Typography mt={"20px"}>List of Used Nfts </Typography> */}
            <Box>
              <Box sx={{ marginTop: "10px" }}>
                {loading ? (
                  <Box display="flex" justifyContent="center" mt={3}>
                    <CircularProgress size={70} sx={{ color: "black" }} />
                  </Box>
                ) : (
                  <CustomTable
                    data={
                      searchDataValue?.length
                        ? searchedData
                        : !searchDataValue?.length
                        ? usedNftsByUser
                        : []
                    }
                    columns={columns}
                    actions={true}
                    onDeleteClick={handleDeleteClick}
                  />
                )}
              </Box>
            </Box>
          </Box>
          <ToastContainer />
        </Paper>
      </Container>
      <div>
        <Modal
          open={confirmOpen}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"center"}
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Are you sure you want to delete?
              </Typography>
            </Box>
            <Box
              // px={10}
              pt={5}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"center"}
            >
              <Box
                // px={10}
                sx={{ pr: 1 }}
              >
                <Button
                  onClick={handleDeleteClickYes}
                  variant="contained"
                  sx={{
                    background: "red",
                    ":hover": {
                      background: "darkred", // Change the color for hover effect
                    },
                  }}
                >
                  Delete
                </Button>
              </Box>
              <Box
                // px={10}
                pl={1}
              >
                <Button onClick={handleCloseModal} variant="contained">
                  cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default UsedNftsComp;
