import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar, Alert, makeStyles } from "@mui/material";
import { setSnackbar } from "../../redux/Snackbar/SnackbarReducder";

interface SnackbarState {
  snackbarOpen: boolean;
  snackbarType: scnakbarColor;
  snackbarMessage: string;
}
type scnakbarColor = "error" | "success" | "info" | "warning" | undefined;

const CustomizedSnackbars: React.FC = () => {
  const dispatch = useDispatch();

  const [openState, setOpenState] = useState<boolean>(false);
  const [snackbarType, setSnackbarType] = useState<scnakbarColor>();
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  const snackbar: SnackbarState = useSelector((state: any) => state.snackbar);

  useEffect(() => {
    setOpenState(snackbar.snackbarOpen);
    setSnackbarType(snackbar.snackbarType);
    setSnackbarMessage(snackbar.snackbarMessage);
  }, [snackbar.snackbarOpen, snackbar.snackbarType, snackbar.snackbarMessage]);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(
      setSnackbar({
        snackbarOpen: false,
        snackbarType: "",
        snackbarMessage: snackbarMessage,
      })
    );
  };

  return (
    <div>
      <Snackbar
        open={openState}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          color={snackbarType}
          severity={snackbarType}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CustomizedSnackbars;
