import React from "react";
import AdminSyncCollectionsComp from "../../Components/Admin-Scenes/AdminSyncCollectionsComp/AdminSyncCollectionsComp";
import AdminPanel from "../Admin/AdminPanel";

function SyncNfts() {
  return (
    <AdminPanel>
      <AdminSyncCollectionsComp />
    </AdminPanel>
  );
}

export default SyncNfts;
