import CampaignListings from "../../Components/Admin-Scenes/Campaigns/Listing/CampaignListings"
import AdminPanel from "./AdminPanel"
const CampaignListing  = () => {
    return(
        <AdminPanel>
            <CampaignListings/>
        </AdminPanel>
    )
}


export default CampaignListing