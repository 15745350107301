import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/system";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {
  Grid,
  TextField,
  FormControl,
  Button,
  Select,
  MenuItem,
  SelectChangeEvent,
  CircularProgress,
  Paper,
  Modal,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { Traits } from "../../../../types/traitsType";
import { Base_Url, axiosRequestLocal } from "../../../api/api";
import { CampaignType } from "../../../../types/campaignTypes";
import OutlinedInput from "@mui/material/OutlinedInput";
import DynamicTableView from "../../Traits Components/DynamicTableView/DynamicTableView";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import type { ToastOptions } from "react-toastify";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  backgroundColor: "white",
  // bgcolor: "background.paper",
  // border: "2px solid #000",
  // boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
interface Column {
  id: "id" | "title" | "layerNumber" | "required" | "createdAt" | "action";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: any) => string | JSX.Element;
}
const columns: Column[] = [
  { id: "id", label: "ID", minWidth: 100 },
  { id: "title", label: "Title", minWidth: 100 }, // Customize the columns here
  { id: "layerNumber", label: "Layer Number", minWidth: 100 }, // Customize the columns here
  { id: "required", label: "Required", minWidth: 100 }, // Customize the columns here
  { id: "createdAt", label: "Created At", minWidth: 170 },
  { id: "action", label: "Action", minWidth: 100 },
];
interface campaigns {
  campaign: CampaignType;
  setRefresh: Function;
  refresh: boolean;
}

interface TraitsData {
  id?: any;
  title: string;
  layerNumber: number;
  required: boolean | undefined;
}
interface LayerFormDataInterface {
  campaignid: number;
  title: string;
  layerNumber: number;
  required: boolean;
}
const validationSchema = Yup.object().shape({
  title: Yup.string()
    .matches(/^[A-Za-z\s]*$/, "Only alphabets are allowed")
    .required("Title is required"),
  layerNumber: Yup.number()
    .required("Layer Number is required")
    .min(1, "Layer number must be greater than 0"),
});

const editValidationSchema = Yup.object().shape({
  title: Yup.string()
    .matches(/^[A-Za-z\s]*$/, "Only alphabets are allowed")
    .required("Title is required"),
  layerNumber: Yup.number()
    .required("Layer Number is required")
    .min(1, "Layer number must be greater than 0"),
});

const TraitUpdate = ({ campaign, setRefresh, refresh }: campaigns) => {
  const campaignid = campaign.campaign_id.toString();

  //--------------------------UseStates----------------------------------------
  const [allCampaigns, setCampaigns] = useState<CampaignType[]>([]);
  // const [campaignid, setCampaignId] = useState("");
  // get data from trait, trait ID
  const [traits, setTraits] = useState<Traits[]>([]);
  // loading
  const [loading, setLoading] = useState(false);

  //  Table View States
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // Confirm dialogue states
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<number>(0); // Add this state to track the selected item's ID for deletion
  // fields useState to add data in table
  const [layerTitle, setLayerTitle] = useState("");
  const [layerNumber, setLayerNumber] = useState<number | undefined>(0);
  // Modal
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<TraitsData | null>(
    null
  );

  const editInitialValues: TraitsData = {
    id: selectedRowData?.id || undefined,
    title: selectedRowData?.title || "",
    layerNumber: selectedRowData?.layerNumber || 0,
    required: selectedRowData?.required,
  };

  const initialInsertValues: LayerFormDataInterface = {
    campaignid: 0,
    title: "",
    layerNumber: 0,
    required: true,
  };
  // --------------------------------------Functions----------------------------------------
  const getAllCampaigns = async () => {
    try {
      const response: any = await axiosRequestLocal(
        "get",
        Base_Url + `/api/admin/campaign`,
        {},
        {}
      );
      if (response.data && response.data.campaigns) {
        setCampaigns(response.data.campaigns);
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  // get Traits according to Campaign Id
  const getTraitsfromCampaignId = async (campaignid: string) => {
    try {
      const response: any = await axiosRequestLocal(
        "get",
        Base_Url + `/api/admin/campaign/${campaignid}`,
        {},
        {}
      );

      if (response.data) {
        const traitData = await response.data.campaign.traits;
        setTraits(traitData);
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  // delete the traitContent image
  const deleteTrait = async (id: number) => {
    setLoading(true);
    try {
      const response: any = await axiosRequestLocal(
        "post",
        Base_Url + `/api/admin/trait/delete/${id}`,
        {},
        {}
      );
      toast.success("Trait Successfully Deleted", { autoClose: 3000 });
      getTraitsfromCampaignId(campaignid);
      setLoading(false);
      setRefresh(!refresh);
    } catch (error: any) {
      console.log("Error fetching data:", error);
      toast.error(error.response.data.message);
    }
  };

  // POST API for Form on add button clicked
  // Define the handleEditSubmit function to handle form submission in the edit modal
  const handleEditSubmit = async (values: TraitsData) => {
    try {
      // Make an API call to update the collection item using values
      // After successfully updating, close the modal and reset edit state
      await updateTraits(values); // Replace with your update API call
      setEditModalOpen(false);
      setSelectedRowData(null);
      // Optionally, refresh the collection data to reflect changes
      getTraitsfromCampaignId(campaignid);
    } catch (error) {
      // Handle any errors here
      console.error("Error updating item:", error);
    }
  };
  const updateTraits = (values: TraitsData) => {
    setLoading(true);

    try {
      const response: any = axiosRequestLocal(
        "put",
        Base_Url + `/api/admin/trait/update/${values.id}`,
        values,
        {}
      );
      toast.success("Traits Successfully Updated", { autoClose: 3000 });
      setLoading(false);

      getTraitsfromCampaignId(campaignid);
    } catch (error: any) {
      console.log("Error fetching data:", error);
      toast.error(error.response.data.message);
    }
  };
  // ------------------------------Handle Dynamic Table View Functions---------------------
  // Table View

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleDelete = async (id: number) => {
    setSelectedItemId(id);
    setConfirmOpen(true); // open the confirmation dialog
  };
  const handleEdit = (id: number) => {
    // Find the collection data with the given id
    const traitToEdit = traits.find((traits) => traits.id === id);

    if (traitToEdit) {
      // Create a new object of type CollectionData with the necessary properties
      const traitData: TraitsData = {
        id: traitToEdit.id || 0, // Use 0 or another default value if 'id' is undefined
        title: traitToEdit.title,
        layerNumber: traitToEdit.layerNumber,
        required: traitToEdit.required,
      };

      // Set the selected row data and open the edit modal
      setSelectedRowData(traitData);
      setEditModalOpen(true);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // POST API for Form on add button clicked
  const handleFormSubmit = async (
    values: LayerFormDataInterface,
    actions: FormikHelpers<LayerFormDataInterface>
  ) => {
    const payload = {
      campaignId: campaignid,
      title: values.title,
      layerNumber: values.layerNumber,
      required: values.required,
    };

    // Handle the case where background and logo are File objects or null
    setLoading(true);
    try {
      const response = await axiosRequestLocal(
        "post",
        `${Base_Url}/api/admin/trait`,
        payload,
        {}
      );
      // Handle successful response
      setLoading(false);
      getTraitsfromCampaignId(campaignid);
      toast.success("Trait Successfully Added", { autoClose: 3000 });
      actions.resetForm();
      setRefresh(!refresh);
    } catch (error: any) {
      setLoading(false);
      console.error("An error occurred:", error);
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    getAllCampaigns();
  }, []);

  useEffect(() => {
    if (campaignid !== "") {
      getTraitsfromCampaignId(campaignid);
    }
  }, [campaignid]);

  return (
    <Container sx={{ borderRadius: 4, mt: 2 }}>
      <Paper elevation={4} sx={{ borderRadius: 4 }}>
        <Box
          padding={3}
          // borderRadius={5}
          sx={{
            borderRadius: 4,
            backgroundColor: "white",
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Traits
          </Typography>
          <Grid container spacing={2} sx={{ mt: "2px" }}></Grid>
          <Typography mt={"20px"}>List of Traits </Typography>

          <Box sx={{ marginTop: "10px" }}>
            {loading ? (
              <Box display="flex" justifyContent="center" mt={3}>
                <CircularProgress size={70} sx={{ color: "black" }} />
              </Box>
            ) : (
              <DynamicTableView
                columns={columns}
                data={traits}
                loading={loading}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleDelete={handleDelete}
                showEditIcon={true}
                showDeleteIcon={true}
                handleEdit={handleEdit}
                confirmOpen={confirmOpen}
                setConfirmOpen={setConfirmOpen}
                selectedItemId={selectedItemId}
                deleteFunction={deleteTrait}
              />
            )}
          </Box>
          <Formik
            initialValues={initialInsertValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} sx={{ mt: "2px" }}>
                  {/* -------------------Layer Title------------------ */}
                  <Grid item lg={4} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Layer Title
                    </Typography>
                    <div>
                      <Field
                        name="title"
                        render={({ field, meta }: FieldProps<string>) => (
                          <TextField
                            {...field}
                            variant="outlined"
                            type="text"
                            placeholder="eg: Background"
                            sx={{ marginTop: "10px" }}
                            size="small"
                            margin="normal"
                            error={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      />
                    </div>
                  </Grid>
                  {/*---------------- Layer Number--------------- */}
                  <Grid item lg={4} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Layer Number
                    </Typography>
                    <div>
                      <Field
                        name="layerNumber"
                        render={({ field, meta }: FieldProps<string>) => (
                          <TextField
                            {...field}
                            variant="outlined"
                            type="number"
                            placeholder="Layer number"
                            sx={{ marginTop: "10px" }}
                            margin="normal"
                            size="small"
                            error={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={4} sm={12} xs={12}>
                    <div style={{ marginTop: "30px" }}>
                      <Field
                        name={`required`}
                        render={({ field, meta }: FieldProps<boolean>) => (
                          <div>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={field.value === true}
                                  onChange={(event) => {
                                    field.onChange({
                                      target: {
                                        name: field.name,
                                        value: event.target.checked
                                          ? true
                                          : false,
                                      },
                                    });
                                  }}
                                  name={field.name}
                                  inputProps={{
                                    "aria-label": "required-toggle",
                                  }}
                                />
                              }
                              label="Required"
                            />
                          </div>
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>
                {/*---------------- Add --------------- */}
                <Box display="flex" justifyContent="start" mt="40px">
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "#454545 ",
                      width: "100px",
                      height: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {loading === false ? (
                      "Update"
                    ) : (
                      <CircularProgress size={20} />
                    )}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
        <Modal
          open={isEditModalOpen}
          onClose={() => {
            setEditModalOpen(false);
            setSelectedRowData(null); // Clear selected row data when closing the modal
          }}
        >
          <Paper elevation={4} sx={{ ...style, width: 350, borderRadius: 4 }}>
            <Typography variant="h5">Edit Traits</Typography>
            <Formik
              initialValues={editInitialValues}
              validationSchema={editValidationSchema}
              onSubmit={handleEditSubmit}
            >
              <Form>
                {/* Your form fields go here */}
                <Field
                  name="title"
                  render={({ field, meta }: FieldProps<string>) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth
                      type="text"
                      placeholder="eg: Background"
                      sx={{ marginTop: "10px" }}
                      margin="normal"
                      error={meta.touched && !!meta.error}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                />

                <Field
                  name="layerNumber"
                  render={({ field, meta }: FieldProps<string>) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth
                      type="number"
                      placeholder="Layer Number"
                      sx={{ marginTop: "10px" }}
                      margin="normal"
                      error={meta.touched && !!meta.error}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                />
                <Field
                  name={`required`}
                  render={({ field, meta }: FieldProps<boolean>) => (
                    <div style={{ marginTop: "10px" }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={field.value === true}
                            onChange={(event) => {
                              field.onChange({
                                target: {
                                  name: field.name,
                                  value: event.target.checked ? true : false,
                                },
                              });
                            }}
                            name={field.name}
                            inputProps={{
                              "aria-label": "required-toggle",
                            }}
                          />
                        }
                        label="Required"
                      />
                    </div>
                  )}
                />

                <Box>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: "10px" }}
                  >
                    Update
                  </Button>
                  <Button
                    onClick={() => {
                      setEditModalOpen(false);
                    }}
                    variant="contained"
                    color="error"
                    sx={{ marginTop: "10px", marginLeft: "5px" }}
                  >
                    Close
                  </Button>
                </Box>
              </Form>
            </Formik>
          </Paper>
        </Modal>
      </Paper>
    </Container>
  );
};

export default TraitUpdate;
