import React from "react";
import AdminPanel from "./AdminPanel";
import { Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CampaignInfo from "../../Components/Admin-Scenes/Campaigns/Form/CampaignForm";
import Burnables from "../../Components/Admin-Scenes/Campaigns/Form/BurnablesForm";
import Trait from "../../Components/Admin-Scenes/Campaigns/Form/TraitsForm";
import CollectionGroup from "../../Components/Admin-Scenes/Campaigns/Form/CollectionsForm";
import TraitsContentForm from "../../Components/Admin-Scenes/Campaigns/Form/TraitContentForm";
import TargetMintingForm from "../../Components/Admin-Scenes/Campaigns/Form/TargetMintingForm";
import CampaignRewardBagInfoComp from "../../Components/Admin-Scenes/Campaigns/Form/CampaignRewardBagForm";
const CreateRewardBagCampaign = () => {
  const [expanded, setExpended] = React.useState(true);
  const [refresh, setRefresh] = React.useState(false);

  //console.log("set refresh", refresh);
  return (
    <>
      <AdminPanel>
        <Box>
          <CampaignRewardBagInfoComp setRefresh={setRefresh} />
          {/* <CollectionGroup refresh={refresh} setRefresh={setRefresh} /> */}
          {/* <Trait refresh={refresh} setRefresh={setRefresh} /> */}
          {/* <TraitsContentForm refresh={refresh} setRefresh={setRefresh} /> */}
          {/* <Burnables refresh={refresh} /> */}
          {/* <TargetMintingForm refresh={refresh} /> */}
        </Box>
      </AdminPanel>
    </>
  );
};

export default CreateRewardBagCampaign;
