import { Box, Container, color } from "@mui/system";
import React, { Ref, forwardRef, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  Input,
  Button,
  Select,
  MenuItem,
  InputAdornment,
  CircularProgress,
  IconButton,
  Paper,
} from "@mui/material";
import {
  ErrorMessage,
  Field,
  FieldArray,
  FieldProps,
  Formik,
  FormikHelpers,
  FormikValues,
} from "formik";
import * as Yup from "yup";
import { axiosRequestLocal } from "../../../api/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import type { ToastOptions } from "react-toastify";
import { Base_Url } from "../../../api/api";
import OutlinedInput from "@mui/material/OutlinedInput";
import { CampaignType } from "../../../../types/campaignTypes";
import { TargetMinting } from "../../../../types/targetMintingtype";

interface FormDataInterface {
  schema: string ;
  description: string ;
  collection_name: string ;
  collection_family: string ;
  base_name: string ;
  taxon: number ;
  nft_type: string ;
  transfer_fee_percentage_x_thousand: number ;
  issuerId: string ;
}

const validationSchema = Yup.object().shape({
  // schema: Yup.string().url("Invalid URL").required("Schema is required"),
  schema: Yup.string().required("Schema is required"),
  description: Yup.string().required("Description is required"),
  collection_name: Yup.string().required("Collection Name is required"),
  collection_family: Yup.string().required("Collection Family is required"),
  base_name: Yup.string()
    .matches(/^[A-Za-z0-9\s]*$/, "Only alphabets and numbers are allowed")
    .required("Base Name is required"),
  taxon: Yup.number()
    .min(1, "Taxon should be greater than 0")
    .required("Taxon is required"),
  nft_type: Yup.string().required("NFT Type is required"),
  transfer_fee_percentage_x_thousand: Yup.number()
  .moreThan(0, "Transfer Fee percentage thousand should be greater than 0")
  .required("Transfer Fee percentage thousand is required"),
  issuerId: Yup.string()
    .matches(/^r[0-9a-zA-Z]{33}$/, "Issuer is not in the correct format")
    .required("Issuer is required"),
});
interface props {
  campaign: CampaignType;
}

const TargetMintings = ({ campaign }: props) => {
  const data = campaign?.targetMinting;
  const campaignid = campaign?.campaign_id;
  const [loading, setLoading] = useState(false);

  const initialValues: FormDataInterface = {
    schema: data?.schema,
    description: data?.description,
    collection_name: data?.collection_name,
    collection_family: data?.collection_family,
    base_name: data?.base_name,
    taxon: data?.taxon,
    nft_type: data?.nft_type,
    transfer_fee_percentage_x_thousand:
      data?.transfer_fee_percentage_x_thousand,
    issuerId: data?.issuerId,
  };
  const handleFormSubmit = async (values: FormDataInterface) => {
    if (values) {
      const payload = {
        campaignId: campaignid,
        nft_type: values.nft_type,
        collection_name: values.collection_name,
        collection_family: values.collection_family,
        schema: values.schema,
        issuerId: values.issuerId,
        base_name: values.base_name,
        taxon: +values.taxon,
        transfer_fee_percentage_x_thousand:
          +values.transfer_fee_percentage_x_thousand,
        description: values.description,
      };
      // Handle the case where background and logo are File objects or null
      setLoading(true);
      try {
        const response: any = await axiosRequestLocal(
          "post",
          Base_Url + `/api/admin/targetminting/update/${data.id}`,
          payload,
          undefined
        );
        toast.success("Target Minting Successfully Updated", {
          autoClose: 3000,
        });
        setLoading(false);

      } catch (error: any) {
        setLoading(false);

        console.error("An error occurred:", error);
        toast.error(error.response.data.message);
      }
    }
  };

  

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  //words
  const totalWords = 200;
  const [wordCount, setWordCount] = useState(0);
  return (
    <Container sx={{ borderRadius: 4, mt: 2 }}>
      <Paper elevation={4} sx={{ borderRadius: 4 }}>
        <Box
          padding={3}
          // borderRadius={5}
          sx={{
            borderRadius: 4,
            backgroundColor: "white",
          }}
        >
          <Typography variant="h5" sx={{fontWeight:600 }}>Target Minting</Typography>
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} sx={{ mt: "2px" }}>
                  
                  {/* ------------------nft_type------------------- */}

                  <Grid lg={4} sm={12} xs={12} item>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      NFT Type
                    </Typography>
                    <div>
                      <Field name="nft_type">
                        {({ field, meta }: FieldProps<string>) => (
                          <TextField
                            id="outlined-size-small"
                            type="text"
                            placeholder="Nft Type"
                            size="small"
                            sx={{ marginTop: "2px", width: "250px" }}
                            {...field}
                            error={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </div>
                  </Grid>
                  {/* ------------------collection_name------------------- */}

                  <Grid lg={4} sm={12} xs={12} item>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Collection Name
                    </Typography>
                    <div>
                      <Field name="collection_name">
                        {({ field, meta }: FieldProps<string>) => (
                          <TextField
                            id="outlined-size-small"
                            type="text"
                            placeholder="Collection Name"
                            size="small"
                            sx={{ marginTop: "2px", width: "250px" }}
                            {...field}
                            error={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </div>
                  </Grid>

                  {/* ------------------collection_family------------------- */}

                  <Grid lg={4} sm={12} xs={12} item>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Collection Family
                    </Typography>
                    <div>
                      <Field name="collection_family">
                        {({ field, meta }: FieldProps<string>) => (
                          <TextField
                            id="outlined-size-small"
                            type="text"
                            placeholder="Collection Family"
                            size="small"
                            sx={{ marginTop: "2px", width: "250px" }}
                            {...field}
                            error={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </div>
                  </Grid>

                  {/* ------------------schema------------------- */}
                  <Grid item lg={6} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Schema
                    </Typography>
                    <div>
                      <Field name="schema">
                        {({ field, meta }: FieldProps<string>) => (
                          <TextField
                            id="outlined-size-small"
                            type="text"
                            placeholder="Schema"
                            size="small"
                            sx={{ marginTop: "2px", width: "85%" }}
                            {...field}
                            error={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </div>
                  </Grid>
                  {/* ------------------Issuer------------------- */}

                  <Grid lg={6} sm={12} xs={12} item>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Issuer
                    </Typography>
                    <div>
                      <Field name="issuerId">
                        {({ field, meta }: FieldProps<string>) => (
                          <TextField
                            id="outlined-size-small"
                            type="text"
                            placeholder="Issuer"
                            size="small"
                            sx={{ marginTop: "2px", width: "81%" }}
                            {...field}
                            error={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </div>
                  </Grid>

                  {/* ------------------base_name------------------- */}

                  <Grid lg={4} sm={12} xs={12} item>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Base Name
                    </Typography>
                    <div>
                      <Field name="base_name">
                        {({ field, meta }: FieldProps<string>) => (
                          <TextField
                            id="outlined-size-small"
                            type="text"
                            placeholder="Base Name"
                            size="small"
                            sx={{ marginTop: "2px", width: "250px" }}
                            {...field}
                            error={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </div>
                  </Grid>
                  {/* ------------------taxon------------------- */}

                  <Grid lg={4} sm={12} xs={12} item>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Taxon
                    </Typography>
                    <div>
                      <Field name="taxon">
                        {({ field, meta }: FieldProps<string>) => (
                          <TextField
                            id="outlined-size-small"
                            type="text"
                            placeholder="Taxon"
                            size="small"
                            sx={{ marginTop: "2px", width: "250px" }}
                            {...field}
                            error={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </div>
                  </Grid>

                  {/* ------------------transfer_fee_percentage_x_thousand------------------- */}

                  <Grid lg={4} sm={12} xs={12} item>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Transfer Fee
                    </Typography>
                    <div>
                      <Field name="transfer_fee_percentage_x_thousand">
                        {({ field, meta }: FieldProps<string>) => (
                          <TextField
                            id="outlined-size-small"
                            type="text"
                            placeholder="Transfer Fee"
                            size="small"
                            sx={{ marginTop: "2px", width: "250px" }}
                            {...field}
                            error={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </div>
                  </Grid>

                  {/* ------------- Description ------------- */}

                  <Grid item lg={12} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Description
                    </Typography>
                    <div style={{ position: "relative" }}>
                      <TextField
                        size="small"
                        sx={{ marginTop: "2px", width: "91%" }}
                        type="text"
                        multiline
                        placeholder="Description"
                        onBlur={handleBlur}
                        onChange={(event) => {
                          handleChange(event);
                          const characterCount = event.target.value.length;
                          setWordCount(characterCount);
                          setFieldValue("description", event.target.value);
                        }}
                        value={values.description}
                        name="description"
                        inputProps={{
                          style: {
                            height: "90px",
                            width: "100%",
                            paddingRight: "70px", // Add space for the word count
                          },
                          maxLength: 200,
                        }}
                        error={!!touched.description && !!errors.description}
                        helperText={touched.description && !!errors.description}
                      />
                      <Typography
                        sx={{
                          position: "absolute",
                          bottom: "8px",
                          right: "109px",
                          color: "#555555",
                          fontSize: "16px",
                        }}
                      >
                        {wordCount} / {totalWords}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>

                {/*--------Submut Button-------- */}

                <Box display="flex" justifyContent="start" mt="40px">
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "#454545",
                      width: "100px",
                      height: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {loading === false ? (
                      "Update"
                    ) : (
                      <CircularProgress size={20} />
                    )}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Paper>
      <ToastContainer />
    </Container>
  );
};

export default TargetMintings;
