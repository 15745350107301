import React from "react";
import Card from "../Card/Card";
import {
  Box,
  Container,
  Grid,
  Typography,
  Divider,
  Button,
  IconButton,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { useState, useEffect } from "react";
import { CampaignType } from "../../Campaigns/../../../types/campaignTypes";
import { Base_Url, axiosRequestLocal } from "../../../api/api";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import { setCampaigns } from "../../../../redux/CampaignsReducer/CampaignsReducer";

const CampaignListings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentRouteName = location.pathname;
  const [expanded, setExpanded] = React.useState(false);
  const [data, setData] = useState<CampaignType[] | null>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const dispatch = useDispatch();

  const loged = useSelector((state: any) => state.xumm?.account);
  const [update, setupdate] = useState(false);
  const [loading, setLoading] = useState(true);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const accountAddress = localStorage.getItem("accnum");

  //  ------------------Function--------------------------
  const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    //console.log("what is this---->", data!.slice(startIndex, endIndex));
    return data!.slice(startIndex, endIndex);
  };
  // Handle page change
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setLoading(true);
    setCurrentPage(newPage);
    setLoading(false);
  };
  const getCampaigns = async (): Promise<void> => {
    setLoading(true);
    try {
      const response: any = await axiosRequestLocal(
        "get",
        Base_Url + `/api/admin/campaign`
      );
      setData(response.data.campaigns);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      //console.log(error);
    }
  };
  const getRewardBagCampaigns = async (): Promise<void> => {
    setLoading(true);
    try {
      const response: any = await axiosRequestLocal(
        "get",
        Base_Url + `/api/admin/rewardbagcampaignv2`
      );
      setData(response.data.campaigns);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      //console.log(error);
    }
  };
  const handleButton = () => {
    if (currentRouteName?.includes("rewardbag"))
      navigate("/admin-panel/create-rewardbag-campaign");
    else navigate("/admin-panel/create-campaign");
  };
  const deleteCampaigns = async (id: any) => {
    try {
      //console.log("refresh");
      const deleteCampaigns = await axiosRequestLocal(
        "delete",
        Base_Url + `/api/admin/campaign/${id}`
      );
      //console.log("responseconsoled", deleteCampaigns);

      //  setReferesh(!refresh);
      toast.success(`Campaign is Successfully Deleted `);
      setupdate(!update);
    } catch (error: any) {
      //console.log("error consoled", error);
      if (error.response.status === 404) {
        toast.error(`This campaign is not existing`);
      } else {
        toast.error(error.response.data.message);
      }
    }
  };
  const deleteRewardBagCampaigns = async (id: any) => {
    try {
      //console.log("refresh");
      const deleteCampaigns = await axiosRequestLocal(
        "delete",
        Base_Url + `/api/admin/v2/rewardbagcampaign/delete/${id}`
      );
      //console.log("responseconsoled", deleteCampaigns);

      //  setReferesh(!refresh);

      if (deleteCampaigns.status) {
        toast.success(`Campaign is Successfully Deleted `);
        setupdate(!update);
      }
    } catch (error: any) {
      //console.log("error consoled", error);
      if (error.response.status === 404) {
        toast.error(`This campaign is not existing`);
      } else {
        toast.error(error.response.data.message);
      }
    }
  };

  const checkAdmin = async (accountAddress: string): Promise<void> => {
    try {
      //console.log("LOGGED --->", accountAddress);
      if (accountAddress && accountAddress.length) {
        let URL = `${Base_Url}/api/admin/check/${accountAddress}`;
        //console.log(URL);
        const response: any = await axiosRequestLocal("get", URL);
        //console.log("Response of IsAdmin", response.data.status);
        if (response.data && response.data.status) {
          // if (currentRouteName.includes("rewardbag"))
          //   navigate("/admin-panel/rewardbag");
          // else navigate("/admin-panel");
        } else {
          //console.log("--------------->");
          navigate("/");
        }
      } else {
        navigate("/");
      }
    } catch (error) {
      //console.log("Response of IsAdmin", error);
      navigate("/");
      //console.log(error);
    }
  };

  function renderCampaigns(loading: any, deleteCampaigns: any) {
    const currentPageData = getCurrentPageData();
    if (currentPageData && currentPageData.length > 0) {
      return (
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 3, sm: 2, md: 4 }}
          sx={{ mt: 2 }}
        >
          {currentPageData.map((d, i) => (
            <Grid item xs={4} key={i}>
              <Card campaign={d} deleteOne={deleteCampaigns} />
            </Grid>
          ))}
        </Grid>
      );
    } else if (loading) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "50px",
          }}
        >
          <CircularProgress
            size={70}
            sx={{ color: "linear-gradient(to right, #000428, #004e92)" }}
          />
        </Box>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "100px",
            width: "100%",
          }}
        >
          <h4 style={{ paddingRight: "140px" }}>
            No Campaign Available at the Moment !
          </h4>
        </div>
      );
    }
  }

  useEffect(() => {
    document.body.style.overflow = "auto";
    if (accountAddress) {
      checkAdmin(accountAddress);
    }
    dispatch(setCampaigns(null));
    if (currentRouteName.includes("rewardbag")) getRewardBagCampaigns();
    else getCampaigns();
  }, [update, currentRouteName]);

  return (
    <Container style={{ overflow: "hidden" }}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box>
          {currentRouteName.includes("rewardbag") ? (
            <Typography variant="h4" sx={{ color: "#444444" }}>
              {" Pack Openings"}
            </Typography>
          ) : (
            <Typography variant="h4" sx={{ color: "#444444" }}>
              {"Creation Lab"}
            </Typography>
          )}
        </Box>
        <Box>
          {/* <Button size="small" onClick={handleButton} variant="contained">
              +
            </Button> */}
          <Tooltip title="Add Campaign">
            <IconButton sx={{ pb: -4 }} aria-label="Add Campaign">
              <AddIcon
                color={"primary"}
                fontSize={"large"}
                onClick={handleButton}
              />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Divider sx={{ paddingInline: 2 }} />
      <Box sx={{ height: loading ? "580px" : "2%" }}>
        {currentRouteName.includes("rewardbag")
          ? renderCampaigns(loading, deleteRewardBagCampaigns)
          : !currentRouteName.includes("rewardbag")
          ? renderCampaigns(loading, deleteCampaigns)
          : ""}
      </Box>
      {/* Material-UI Pagination Component */}

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          count={Math.ceil(data!.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          shape="rounded"
        />
      </Box>

      <ToastContainer />
    </Container>
  );
};

export default CampaignListings;
