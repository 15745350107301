import * as React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
  Button,
  Modal,
  Box,
} from "@mui/material";
import { CampaignType } from "../../../types/campaignTypes";
import { useLocation, useNavigate } from "react-router-dom";
import { Base_Url } from "../../api/api";
import BackgroundImg from "../../../assets/Background/backgroundImage2.jpg";

interface campaignPropI {
  data: CampaignType;
}

const Cards = ({ data }: campaignPropI) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showModal, setShowModal] = React.useState(false);
  const currentRouteName = location.pathname;

  const handleModalClose = () => setShowModal(false);

  let backendImage = currentRouteName.includes("rewardbag")
    ? `${Base_Url}/rewardBagBackground/${data?.background}`
    : `${Base_Url}/${data?.background}`;

  return (
    <>
      <div
        onClick={() => {
          if (currentRouteName.includes("rewardbag")) {
            setShowModal(true);
          } else {
            navigate(`/breeding/${data?.campaign_id}`);
          }
        }}
        style={{ textDecoration: "none" }}
      >
        <Card
          sx={{
            maxWidth: 345,
            minWidth: 344,
            height: 300,
            borderRadius: 6,
            position: "relative",
            overflow: "hidden",
            background: "rgba(192, 200, 225, 0.4)",
            backdropFilter: "blur(10px)",
            WebkitBackdropFilter: "blur(2px)",
            border: "1px solid rgba(255, 255, 255, 0.18)",
            transition: "transform 0.3s ease-in-out",
            "&:hover": {
              transform: "scale(1.05)",
            },
          }}
        >
          <div
            style={{
              backgroundImage: `url(${Base_Url}/background/${data?.background})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
              height: "100%",
              position: "absolute",
              zIndex: -1,
              filter: "blur(10px)",
            }}
          />
          <CardActionArea>
            <CardMedia
              component="img"
              height="200px"
              image={backendImage === "" ? BackgroundImg : backendImage}
              alt="NFT Image"
            />
            <CardContent
              sx={{
                height: 90,
                overflow: "hidden",
                background: "rgba(192, 200, 225, 0.4)",
                border: "1px solid rgba(255, 255, 255, 0.18)",
                margin: "0px",
              }}
            >
              <Typography variant="h6" color="white" fontFamily="jedi">
                {data?.name &&
                  (data?.name?.length <= 20
                    ? data?.name
                    : data?.name?.substring(0, 20) + " ....")}
              </Typography>
              <Typography color="white" fontSize="15px" fontFamily="jedi">
                {data?.description?.length <= 50
                  ? data?.description
                  : data?.description?.substring(0, 50) + " ...."}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>

      {/* Modal for Delux Mint */}
      <Modal open={showModal} onClose={handleModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "30vw",
            background:
              "linear-gradient(to top left, rgba(0, 4, 40, 1), rgba(175, 34, 200, 1))",
            border: "1px solid white",
            borderRadius: 4,
            boxShadow: 24,
            p: 6,
            textAlign: "center",
          }}
        >
          <Typography
            variant="h1"
            component="h1"
            fontSize={"26px"}
            sx={{ mb: 2, color: "white", fontWeight: "bold" }}
          >
            DELUXMINT
          </Typography>
          <Typography
            variant="h6"
            component="h5"
            fontSize={"16px"}
            sx={{ mb: 2, color: "yellow" }}
          >
            Kindly go to DeluxMint to view campaigns
          </Typography>
          <Button
            variant="contained"
            onClick={() =>
              window.open(
                "https://mint.luxlionsnft.com/packs",
                "_blank",
                "noopener,noreferrer"
              )
            }
            size="small"
            sx={{
              color: "white",
              background: "black",
              mt: "10px",
              borderRadius: 20,
              padding: 1,
              paddingX: 3,
              border: "1px solid #6DF8FE",
              "&:hover": {
                background: "purple",
                color: "white",
              },
            }}
          >
            Go to Campaigns
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default Cards;
