import React, { ReactNode } from "react";
import Box from "@mui/material/Box";
import { AppBar, Drawer, useMediaQuery } from "@mui/material";
import Appbar from "../../Components/Admin-Scenes/appBar/appBar";

// Formik

const AdminPanel = (props: { children: ReactNode }) => {  
  const isNonMobile: any = useMediaQuery("(min-width: 600px)");
  return (
    <>
      {/* <Box display={isNonMobile ? "flex" : "block"} width="100%" height="100%"> */}
        {/* <Sidebar /> */}


        {/* <Box
          sx={{
            width: "100%",
            height: "92vh",
            backgroundColor: "#eceff1",
            overflowY: "auto",
            marginTop: "48px"
          }}
        >
          {props.children}
        </Box> */}
      {/* </Box> */}
      <div>
<Box sx={{height:'7vh', display:'flex', alignItems:'center'}}>
<Appbar />
</Box>
<Box sx={{minHeight:'90vh', backgroundColor:"#eceff1",paddingTop:"20px"}}> {props.children}</Box>
      </div>
    </>
  );
};

export default AdminPanel;
