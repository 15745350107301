import { Box, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import CardMedia from "@mui/material/CardMedia";
import Logo from "../../assets/BreedingAssets/logo.png";
import SimpleCampaign from "../../assets/Campaign/campaign.webp";
import RewardCampaign from "../../assets/Campaign/reward.webp";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import backgroundImage from "../../assets/Background/backgroundImage2.jpg";

function SelectCampaign() {
  const navigate = useNavigate();
  const mobileViewCheck = useMediaQuery("(min-width:1023px)");

  return (
    <div
      style={{
        background: "linear-gradient(to right, #000428, #af22c8)",
        width: "100%",
        height: "100vh",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          pt: 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={Logo} alt="Lux Labs" />
      </Box>
      <Box
        sx={{
          display: mobileViewCheck ? "flex" : "",
          justifyContent: "space-evenly",
          marginTop: "30px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            style={{ color: "white", letterSpacing: "1px", fontFamily: "jedi" }}
          >
            Creation Lab
          </Typography>

          <Box
            sx={{
              mt: "4px",
              width: "fit",
              cursor: "pointer",
              transition: "transform 0.3s",
              ":hover": {
                transform: "scale(1.05)",
                boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
              },
            }}
            onClick={() => {
              navigate("/campaigns");
            }}
          >
            <img
              src={SimpleCampaign}
              alt="img"
              style={{
                width: mobileViewCheck ? "320px" : "240px",
                height: mobileViewCheck ? "320px" : "240px",
                borderRadius: 6,
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            style={{ color: "white", letterSpacing: "1px", fontFamily: "jedi" }}
          >
            Pack Openings
          </Typography>
          <Box
            sx={{
              mt: "4px",
              width: "fit",
              cursor: "pointer",
              transition: "transform 0.3s",
              ":hover": {
                transform: "scale(1.05)",
                boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
              },
            }}
            onClick={() => {
              navigate("/campaigns/rewardbag");
            }}
          >
            <img
              src={RewardCampaign}
              alt="img"
              style={{
                width: mobileViewCheck ? "320px" : "240px",
                height: mobileViewCheck ? "320px" : "240px",
                borderRadius: 6,
              }}
            />
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default SelectCampaign;
