import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/system";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  Input,
  Button,
  Select,
  MenuItem,
  SelectChangeEvent,
  Stack,
  CircularProgress,
  Paper,
  Modal,
} from "@mui/material";
import {
  ErrorMessage,
  Field,
  FieldArray,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
} from "formik";
import * as Yup from "yup";
import TraitsCard from "./TraitsCard";
import { Traits } from "../../../../types/traitsType";
import {
  Base_Url,
  axiosRequestLocal,
  axiosRequestAuth,
} from "../../../api/api";
import { CampaignType } from "../../../../types/campaignTypes";
import OutlinedInput from "@mui/material/OutlinedInput";
import DynamicTableView from "../../Traits Components/DynamicTableView/DynamicTableView";
import { Burnables } from "../../../../types/Burnables";
import { ToastContainer, toast } from "react-toastify";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  backgroundColor: "white",
  // bgcolor: "background.paper",
  // border: "2px solid #000",
  // boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface Column {
  id: "id" | "name" | "taxonId" | "createdAt" | "action";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: any) => string | JSX.Element;
}
const columns: Column[] = [
  { id: "id", label: "ID", minWidth: 100 },
  { id: "name", label: "Name", minWidth: 100 }, // Customize the columns here
  { id: "taxonId", label: "Taxon Id", minWidth: 100 }, // Customize the columns here
  { id: "createdAt", label: "Created At", minWidth: 170 },
  { id: "action", label: "Action", minWidth: 100 },
];

interface BurnablesData {
  id: any;
  name: string;
  taxonId: string;
}

interface FormDataInterface {
  name: string;
  taxonId: string;
  campaignid: string;
}
const initialValues: FormDataInterface = {
  name: "",
  taxonId: "",
  campaignid: "",
};

const editValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  taxonId: Yup.number()
    .min(1, "Taxon should be greater than 0")
    .required("Taxon Id is required"),
});

const xummWalletAddressRegex = /^r[a-z0-9]{24,34}$/i;
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  taxonId: Yup.number()
    .min(1, "Taxon should be greater than 0")
    .required("Taxon Id is required"),
});
interface campaigns {
  campaign: CampaignType;
}

const BurnablesUpdateRewardBag = ({ campaign }: campaigns) => {
  // useStates
  const campaignid = campaign.campaign_id.toString();

  const [allCampaigns, setCampaigns] = useState<CampaignType[]>([]);
  // const [campaignid, setCampaignId] = useState("");
  // get data from trait, trait ID
  const [burnables, setBurables] = useState<Burnables[]>([]);
  // loading

  const [loading, setLoading] = useState(false);

  //  Table View States
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // Confirm dialogue states
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<number>(0); // Add this state to track the selected item's ID for deletion

  // modal useState
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<BurnablesData | null>(
    null
  );
  // Functions
  const getAllCampaigns = async () => {
    //console.log("Working to get campaign Id");
    try {
      const response: any = await axiosRequestLocal(
        "get",
        Base_Url + `/api/admin/rewardbagcampaign`,
        {},
        {}
      );
      if (response.data && response.data.campaigns) {
        setCampaigns(response.data.campaigns);
      }
      //console.log(allCampaigns);
    } catch (error) {
      //console.log("Error fetching data:", error);
    }
  };
  // POST API for Form on add button clicked
  const handleFormSubmit = async (
    values: FormDataInterface,
    actions: FormikHelpers<FormDataInterface>
  ) => {
    const payload = {
      campaignid: campaignid,
      name: values.name,
      taxonId: values.taxonId,
    };

    // Handle the case where background and logo are File objects or null
    setLoading(true);
    try {
      const response: any = await axiosRequestAuth(
        "post",
        `api/admin/v2/rewardbagburnables`,
        payload,
        undefined
      );
      //console.log(response, "Burnable done");
      setLoading(false);
      actions.resetForm();
      toast.success("Burnables Successfully Added", { autoClose: 3000 });
      // setTimeout(()=>
      // {
      setLoading(false);
      // },2000);
      getBurnablefromCampaignId(campaignid);
    } catch (error: any) {
      setLoading(false);
      console.error("An error occurred:", error);
      toast.error(error.response.data.message);
    }
  };
  // get Burnable according to Campaign Id
  const getBurnablefromCampaignId = async (campaignid: string) => {
    //console.log("Working to get campaign Id");

    try {
      const response: any = await axiosRequestLocal(
        "get",
        Base_Url + `/api/admin/v2/rewardbagburnables/${campaignid}`,
        {},
        {}
      );

      if (response.status) {
        const burnableData = await response.data?.rewardBagCampaign;

        setBurables(burnableData);
      }
    } catch (error) {
      //console.log("Error fetching data:", error);
    }
  };

  // delete collection
  const deleteBurnables = async (id: number) => {
    try {
      const response: any = await axiosRequestLocal(
        "delete",
        Base_Url + `/api/admin/rewardbagburnables/${id}`,
        {},
        {}
      );
      toast.success("Burnables Successfully Deleted", { autoClose: 3000 });
      getBurnablefromCampaignId(campaignid);
    } catch (error: any) {
      //console.log("Error fetching data:", error);
      toast.error(error.response.data.message);
    }
  };
  //console.log(burnables, "asdasd");
  // Table View
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleDelete = (id: number) => {
    setSelectedItemId(id);
    setConfirmOpen(true);
  };

  const handleEdit = (id: number) => {
    // Find the collection data with the given id
    const burnablesToEdit = burnables.find((burnables) => burnables.id === id);

    if (burnablesToEdit) {
      // Create a new object of type CollectionData with the necessary properties
      const collectionData: BurnablesData = {
        id: burnablesToEdit.id || 0, // Use 0 or another default value if 'id' is undefined
        name: burnablesToEdit.name,
        taxonId: burnablesToEdit.taxonId,
      };

      // Set the selected row data and open the edit modal
      setSelectedRowData(collectionData);
      setEditModalOpen(true);
    }
  };
  // Define the handleEditSubmit function to handle form submission in the edit modal
  const handleEditSubmit = async (values: BurnablesData) => {
    try {
      // Make an API call to update the collection item using values
      // After successfully updating, close the modal and reset edit state
      await updateBurnables(values); // Replace with your update API call
      setEditModalOpen(false);
      setSelectedRowData(null);
      // Optionally, refresh the collection data to reflect changes
      getBurnablefromCampaignId(campaignid);
    } catch (error) {
      // Handle any errors here
      console.error("Error updating item:", error);
    }
  };

  const updateBurnables = (values: BurnablesData) => {
    setLoading(true);
    //console.log("On Upadte", values);
    try {
      const response: any = axiosRequestLocal(
        "put",
        Base_Url + `/api/admin/rewardbagburnables/update/${values.id}`,
        values,
        {}
      );
      toast.success("Burnables Updated Successfully", { autoClose: 3000 });
      // setTimeout(()=>
      // {
      setLoading(false);
      // },2000);
      getBurnablefromCampaignId(campaignid);
    } catch (error: any) {
      setLoading(false);
      //console.log("Error fetching data:", error);
      toast.error(error.response.data.message);
    }
  };

  const editInitialValues: BurnablesData = {
    id: selectedRowData?.id || undefined,
    name: selectedRowData?.name || "",
    taxonId: selectedRowData?.taxonId || "",
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // // useEffects
  // useEffect(() => {
  //   getAllCampaigns();
  // }, []);
  // // useEffects
  useEffect(() => {
    if (campaignid !== "") {
      getBurnablefromCampaignId(campaignid);
    }
  }, [campaignid]);

  return (
    <Container sx={{ borderRadius: 4, mt: 2 }}>
      <Paper elevation={4} sx={{ borderRadius: 4 }}>
        <Box
          padding={3}
          // borderRadius={5}
          sx={{
            borderRadius: 4,
            backgroundColor: "white",
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Burnables
          </Typography>

          <Formik
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} sx={{ mt: "2px" }}></Grid>
                <Typography mt={"20px"}>List of Burnables</Typography>

                <Box sx={{ marginTop: "10px" }}>
                  {loading ? (
                    <Box display="flex" justifyContent="center" mt={3}>
                      <CircularProgress size={70} sx={{ color: "black" }} />
                    </Box>
                  ) : (
                    <DynamicTableView
                      columns={columns}
                      data={burnables}
                      loading={loading}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                      handleDelete={handleDelete}
                      showEditIcon={true}
                      showDeleteIcon={true}
                      handleEdit={handleEdit}
                      confirmOpen={confirmOpen}
                      setConfirmOpen={setConfirmOpen}
                      selectedItemId={selectedItemId}
                      deleteFunction={deleteBurnables}
                    />
                  )}
                </Box>
                <Grid container spacing={2} sx={{ mt: "2px" }}>
                  <Grid item lg={4} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Name
                    </Typography>
                    <div>
                      <Field name="name">
                        {({ field, meta }: FieldProps<string>) => (
                          <TextField
                            id="outlined-size-small"
                            type="text"
                            size="small"
                            placeholder="Name"
                            sx={{ marginTop: "2px", width: "250px" }}
                            {...field}
                            error={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </div>
                  </Grid>
                  {/*---------------- Taxon Id --------------- */}

                  <Grid item lg={4} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Taxon Id
                    </Typography>
                    <div>
                      <TextField
                        placeholder="Taxon Id"
                        id="outlined-size-small"
                        size="small"
                        sx={{ marginTop: "2px", width: "250px" }}
                        type="number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.taxonId}
                        name="taxonId"
                        error={!!touched.taxonId && !!errors.taxonId}
                        helperText={touched.taxonId && !!errors.taxonId}
                      ></TextField>
                    </div>
                  </Grid>

                  {/*---------------- Typography --------------- */}
                </Grid>
                <Box display="flex" justifyContent="start" mt="40px">
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "#454545 ",
                      width: "100px",
                      height: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {loading === false ? (
                      "Update"
                    ) : (
                      <CircularProgress size={20} />
                    )}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
        {isEditModalOpen && (
          <Modal
            open={isEditModalOpen}
            onClose={() => {
              setEditModalOpen(false);
              setSelectedRowData(null); // Clear selected row data when closing the modal
            }}
          >
            <Paper elevation={4} sx={{ ...style, width: 350, borderRadius: 4 }}>
              <Typography variant="h5">Edit Burnables</Typography>

              <Formik
                initialValues={editInitialValues}
                onSubmit={handleEditSubmit}
                validationSchema={editValidationSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    {/* Your form fields go here */}
                    <Field
                      name="name"
                      render={({ field, meta }: FieldProps<string>) => (
                        <TextField
                          {...field}
                          variant="outlined"
                          fullWidth
                          type="text"
                          placeholder="Name"
                          sx={{ marginTop: "10px" }}
                          margin="normal"
                          error={meta.touched && !!meta.error}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    />
                    {/* <Field name="name">
                      {({ field, meta }: FieldProps<string>) => (
                        <TextField
                          id="outlined-size-small"
                          type="text"
                          size="small"
                          placeholder="Name"
                          sx={{ marginTop: "2px", width: "250px" }}
                          {...field}
                          error={meta.touched && !!meta.error}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    </Field>{" "} */}
                    <TextField
                      placeholder="Taxon Id"
                      id="outlined-size-small"
                      sx={{ marginTop: "10px", width: "100%" }}
                      type="number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.taxonId}
                      name="taxonId"
                      error={!!touched.taxonId && !!errors.taxonId}
                      helperText={touched.taxonId && !!errors.taxonId}
                    ></TextField>
                    <Box sx={{ marginTop: "10px" }}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ marginTop: "10px" }}
                      >
                        Update
                      </Button>
                      <Button
                        onClick={() => {
                          setEditModalOpen(false);
                        }}
                        variant="contained"
                        color="error"
                        sx={{ marginTop: "10px", marginLeft: "5px" }}
                      >
                        Close
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Paper>
          </Modal>
        )}
      </Paper>
      <ToastContainer />
    </Container>
  );
};

export default BurnablesUpdateRewardBag;
