import "./App.css";
import "animate.css";
import BackgroundImage from "./assets/Background/backgroundImage.png";
import AdminBackgroundImage from "./assets/Background/adminbackground.jpg";
import { StyledEngineProvider } from "@mui/material/styles";
import CustomizedSnackbars from "./Components/SnackbarComp/SnackbarComp";
import AllRoutes from "./Routes/Routes";
import AdminPanel from "./Pages/Admin/AdminPanel";
import { useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { GlobalStyle } from "./Components/user/Campaign/Globalstyle";
function App() {
  const userRole = "user";
  const isAdmin = window.location.href.includes("admin");

  // useEffect(() => {
  //   if (isAdmin) {
  //     document.body.style.overflow = "auto";
  //   } else {
  //     document.body.style.overflow = "hidden";
  //   }
  // }, [isAdmin]);

  return (
    <StyledEngineProvider injectFirst>
      {/* @ts-ignore */}
      {/* <GlobalStyle/> */}
      <CustomizedSnackbars />
      {userRole === "user" ? (
        <div style={{ position: "relative" }}>
          {/* {!isAdmin && (
            <img
              src={`${AdminBackgroundImage}`}
              alt="img"
              style={{ width: "100%", height: "100vh", position: "absolute" }}
            />
            <div
              style={{ width: "100%", height: "100vh", position: "absolute" }}
            ></div>
          )} */}

          <AllRoutes />
        </div>
      ) : (
        <div style={{ position: "relative" }}>
          {!isAdmin && (
            <img
              src={`${BackgroundImage}`}
              alt="img"
              style={{ width: "100%", height: "100vh", position: "absolute" }}
            />
          )}
          <AllRoutes />
        </div>
      )}
    </StyledEngineProvider>
  );
}

export default App;
