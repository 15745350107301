import React, { useEffect, useState } from "react";
import AdminPanel from "./AdminPanel";
import Single from "../../Components/Admin-Scenes/Campaigns/single/Single";
import { Base_Url, axiosRequestLocal } from "../../Components/api/api";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CampaignType } from "../../types/campaignTypes";
import { setCampaigns } from "../../redux/CampaignsReducer/CampaignsReducer";
import { CampaignTypeRewardBag } from "../../types/campaignTypesRewardBag";

const CampaignDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentRouteName = location.pathname;
  const accountAddress = localStorage.getItem("accnum");

  //console.log("What is campaign id--->", id);
  const [campaign, setCampaign] = useState<CampaignType>();

  // function
  const getCampaignId = async (id: string | undefined) => {
    //console.log("Working to get campaign Id");

    try {
      const response: any = await axiosRequestLocal(
        "get",
        Base_Url + `/api/admin/campaign/${id}`,
        {},
        {}
      );
      //console.log("What is console", response.data.campaign);
      dispatch(setCampaigns(response.data.campaign));
      setCampaign(response.data.campaign);
    } catch (error) {
      //console.log("Error fetching data:", error);
    }
  };
  const getRewardBagCampaignId = async (id: string | undefined) => {
    //console.log("Working to get campaign Id");

    try {
      const response: any = await axiosRequestLocal(
        "post",
        Base_Url + `/api/admin/rewardbagcampaignv2/${id}`,
        {},
        {}
      );
      //console.log("What is console", response.data.campaign);
      dispatch(setCampaigns(response.data.campaign));
      let data: CampaignType = { ...response.data.campaign };
      // Changing the name from "rewardBagCollections" to "collections"
      data.collections = response.data.campaign.rewardBagCollections;
      data.burnables = response.data.campaign.rewardBagBurnables;

      setCampaign(data);
    } catch (error) {
      //console.log("Error fetching data:", error);
    }
  };

  const checkAdmin = async (accountAddress: string): Promise<void> => {
    try {
      //console.log("LOGGED --->", accountAddress);
      if (accountAddress && accountAddress.length) {
        let URL = `${Base_Url}/api/admin/check/${accountAddress}`;
        //console.log(URL);
        const response: any = await axiosRequestLocal("get", URL);
        //console.log("Response of IsAdmin", response.data.status);
        if (response.data && response.data.status) {
          // if (currentRouteName?.includes("rewardbag"))
          //   navigate(`/admin-panel/campaign/rewardbag/${id}`);
          // else navigate(`/admin-panel/campaign/${id}`);
        } else {
          //console.log("--------------->");
          navigate("/");
        }
      } else {
        navigate("/");
      }
    } catch (error) {
      //console.log("Response of IsAdmin", error);
      navigate("/");
      //console.log(error);
    }
  };

  useEffect(() => {
    document.body.style.overflow = "auto";
    if (accountAddress) {
      checkAdmin(accountAddress);
    }

    if (currentRouteName?.includes("rewardbag")) getRewardBagCampaignId(id);
    else getCampaignId(id);
  }, []);

  return (
    <>
      {campaign && (
        <AdminPanel>
          <Single campaignState={campaign} />
        </AdminPanel>
      )}
    </>
  );
};

export default CampaignDetails;
