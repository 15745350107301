import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import DynamicTableView from "../Traits Components/DynamicTableView/DynamicTableView";
import { ToastContainer, toast } from "react-toastify";
import { Base_Url, axiosRequestLocal, axiosRequestAuth } from "../../api/api";
import { useNavigate } from "react-router-dom";
import { Field, FieldProps, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  backgroundColor: "white",
  // bgcolor: "background.paper",
  // border: "2px solid #000",
  // boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

interface FormDataInterface {
  userAccount: string;
}

interface Column {
  id:
    | "id"
    | "campaign.name"
    | "collectionName"
    | "bucketUrl"
    | "mintedKeys"
    | "remainingAwsKeys"
    | "sync";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: any) => string | JSX.Element;
}
const columns: Column[] = [
  { id: "id", label: "Id", minWidth: 50 },
  { id: "campaign.name", label: "Campaign Name", minWidth: 100 },
  { id: "collectionName", label: "Collection Name", minWidth: 100 },
  { id: "bucketUrl", label: "Bucket Url", minWidth: 150 },
  { id: "mintedKeys", label: "Minted Keys", minWidth: 70 },
  { id: "remainingAwsKeys", label: "Remaining Keys", minWidth: 70 },
  { id: "sync", label: "Sync", minWidth: 70 },
];

const AdminManagementComp = () => {
  // use States
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const accountAddress = localStorage.getItem("accnum");

  //  Table View States
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // Confirm dialogue states
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<number>(0); // Add this state to track the selected item's ID for deletion
  const [isEditModalOpen, setEditModalOpen] = useState(false);

  const initialValues: FormDataInterface = {
    userAccount: "",
  };

  const dummyData = [
    {
      id: 100,
      userAccount: "rnB8jr6kZ3HZqcNeTYpvV3HnjUeHKxTLgK",
    },
  ];

  // UsefNfts
  const [adminDetails, setAdminDetails] = useState<any[]>(dummyData);

  const validationSchema = Yup.object().shape({
    userAccount: Yup.string()
      // .matches(/^r[0-9a-zA-Z]{33}$/, "Admin Address is not in the correct format")
      .required("Admin Address is required"),
  });

  //------------------- Functions ----------------------
  const viewDetails = async () => {
    setLoading(true);
    try {
      const response: any = await axiosRequestAuth(
        "get",
        `api/admin/v2/rb_collections`,
        {},
        {}
      );
      if (response.data) {
        const viewadminDetails = response.data.rb_collections_data;
        setAdminDetails(viewadminDetails);
      }
      setLoading(false);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  // Table View
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  //   Delete Prop
  const handleDelete = (id: number) => {
    setSelectedItemId(id);
    setConfirmOpen(true);
  };

  const handleEdit = (id: number) => {
    // Find the collection data with the given id
  };

  const checkAdmin = async (accountAddress: string): Promise<void> => {
    try {
      if (accountAddress && accountAddress.length) {
        let URL = `${Base_Url}/api/admin/check/${accountAddress}`;
        const response: any = await axiosRequestLocal("get", URL);
        if (response.data && response.data.status) {
          navigate(`/admin-panel/rewardbag/sync-collections`);
        } else {
          navigate("/");
        }
      } else {
        navigate("/");
      }
    } catch (error) {
      console.log("Response of IsAdmin", error);
      navigate("/");
    }
  };

  // ------------------------------Use Effects---------------------------------

  useEffect(() => {
    if (accountAddress) {
      checkAdmin(accountAddress);
    }
    viewDetails();
  }, []);

  // delete the traitContent image
  const deleteAdmin = async (id: number) => {};

  const handleSync = async (id: number) => {
    setLoading(true);
    try {
      const response: any = await axiosRequestAuth(
        "post",
        `api/admin/v2/rb_collections/update`,
        { collection_id: id },
        {}
      );
      if (response.status) {
        toast.success("Collection synced.", { autoClose: 3000 });
        viewDetails();
      } else {
        toast.error("Collection sync failed.", { autoClose: 3000 });
      }
      setLoading(false);
    } catch (error) {
      console.log("Error fetching data:", error);
      toast.error("Collection sync failed.", { autoClose: 3000 });
      setLoading(false);
    }
  };

  return (
    <Container sx={{ borderRadius: 4, mt: 2 }}>
      <Paper elevation={4} sx={{ borderRadius: 4 }}>
        <Box
          padding={3}
          // borderRadius={5}
          sx={{
            borderRadius: 4,
            backgroundColor: "white",
          }}
        >
          <Box display="flex" justifyContent={"center"} pb={2}>
            <Typography variant="h4">Sync Collections</Typography>
          </Box>

          <Grid container spacing={2} sx={{ mt: "2px" }}>
            {/*---------------- Select Trait--------------- */}
          </Grid>

          <Typography mt={"20px"}>List of Collections </Typography>
          <Box>
            <Box sx={{ marginTop: "10px" }}>
              {loading ? (
                <Box display="flex" justifyContent="center" mt={3}>
                  <CircularProgress size={70} sx={{ color: "black" }} />
                </Box>
              ) : (
                <DynamicTableView
                  columns={columns}
                  data={adminDetails}
                  loading={loading}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  handleDelete={handleDelete}
                  showEditIcon={false}
                  showDeleteIcon={false}
                  handleEdit={handleEdit}
                  confirmOpen={confirmOpen}
                  setConfirmOpen={setConfirmOpen}
                  selectedItemId={selectedItemId}
                  deleteFunction={deleteAdmin}
                  handleSync={handleSync}
                />
              )}
            </Box>
          </Box>
        </Box>

        <ToastContainer />
      </Paper>
    </Container>
  );
};

export default AdminManagementComp;
