import React, { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import TabPanel from "../../../TabPanels/TabPanels";
import { Box, Grid, Tab, Tabs, TabScrollButton } from "@mui/material";
import { rightBreedRequestBody } from "../DesignGrids";
import styleGrids from "./DesignGridsPanels.module.css";
import Transparent from "../../../../assets/Canvas.png";

import Cross from "../../../../assets/cross.png";
import { Traits } from "../../../../types/traitsType";
import { Base_Url } from "../../../api/api";
import { isPropertyAccessOrQualifiedName } from "typescript";
import { useSelector } from "react-redux";

const crossImg = [{ key: 1, img: Cross, name: "none" }];

interface RightgridPanelI {
  setCanvasObject: React.Dispatch<React.SetStateAction<rightBreedRequestBody>>;
  canvasObject: rightBreedRequestBody;
  nameObject: rightBreedRequestBody;
  setNameObject: React.Dispatch<React.SetStateAction<rightBreedRequestBody>>;
  traitsObject: Traits[] | undefined;
}

function RightGridPanel(props: RightgridPanelI) {
  const traits = props.traitsObject;
  const campaignsSelector = useSelector(
    (state: any) => state.campaigns.campaigns
  );

  const traitsp: any = [];

  traits &&
    traits?.map((d: any, i) => {
      // const images = d.traitContent?.map((tc: any) => tc);
      const images = d.traitContent?.map((tc: any) => ({
        ...tc,
        trait_name: d.title, // Add the trait_name here
        indexNumber: d.layerNumber,
        required: d.required,
        image: `${Base_Url}/${tc.imageName}`,
      }));

      if (images.length > 0 && images[0].required === false) {
        // Hardcoded image data
        const hardcodedImage = {
          // Replace with your hardcoded image properties
          image: Transparent,
          imageName: Transparent,
          trait_name: "transparent",
          indexNumber: 0,
          required: false,
        };

        // Add the hardcoded image as the first element of the images array
        images.unshift(hardcodedImage);
      }

      traitsp.push({
        id: d.id,
        title: d.title,
        layerNumber: d.layerNumber,
        order: d.order,
        traitContent: {
          images: images,
        },
      });
    });

  const [canvasData, setCanvasData] = useState<any>([]);
  const [rightPanelValue, setRightPanelValue] = useState<number>(0);
  const [activeBorder, setActiveBorder] = useState<number>(0);

  const specificImageName = "uploads/transparent/transparent.png";

  const handleChangeRightPanel = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setRightPanelValue(newValue);
  };

  const handleLayer = (item: any, ImageIndex: number, layerNumber: number) => {
    const updatedDataArray = [...canvasData];

    updatedDataArray[layerNumber - 1] = item;

    setCanvasData(updatedDataArray);

    const newCharacterObject = structuredClone(props.canvasObject);

    newCharacterObject.rightChildTraits.layerImage = updatedDataArray;

    props.setCanvasObject(newCharacterObject);
    // For border on the chosen image
    setActiveBorder(ImageIndex);
  };

  return (
    <Box
      mt={1}
      // ml={2}
      sx={{
        background: "rgba(63, 63, 63, 0.9)",
        // height: "90%",
        borderRadius: "10px",
        width: "100%",
        pb: "4px",
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          variant="scrollable"
          value={rightPanelValue}
          onChange={handleChangeRightPanel}
          TabScrollButtonProps={{
            sx: { color: "white", size: "20px" },
          }}
          TabIndicatorProps={{
            sx: {
              backgroundColor: campaignsSelector?.buttonColor,
              paddingInline: -16,
            },
          }}
          sx={{
            "& button.Mui-selected": {
              color: campaignsSelector?.buttonColor,
            },
            padding: -16,
          }}
          // scrollButtons={"auto"}
          // visibleScrollbar={true}
        >
          {traitsp?.map((t: any, arrayIndex: number) => {
            return (
              <Tab
                label={t.title}
                sx={{
                  color: "white",
                  borderRight: `4px solid ${campaignsSelector?.backgroundColor}`,
                  background: "#003548",
                }}
                key={arrayIndex}
              />
            );
          })}
        </Tabs>
      </Box>
      {/* ///////////////////////////////////////////  Layers  ///////////////////////////////////////////////////////// */}
      {traitsp ? (
        traitsp.map((items: any, arrayIndex: number) => {
          return (
            <>
              <TabPanel
                value={rightPanelValue}
                index={arrayIndex}
                key={arrayIndex}
              >
                <Box
                  sx={{ background: "background: rgba(0, 0, 0, 0.5)" }}
                  minHeight="55vh"
                >
                  {items && items.traitContent ? (
                    <Grid
                      container
                      spacing={{ sm: 2 }}
                      columns={{ sm: 9 }}
                      pl={2}
                      minHeight="30vh"
                      maxHeight="30vh"
                    >
                      {items.traitContent.images ? (
                        items.traitContent.images.map(
                          (d: any, imageIndex: number) => {
                            return (
                              <Grid item sm={3} key={imageIndex}>
                                {activeBorder == imageIndex ? (
                                  <Box
                                    width="6vw"
                                    height="10vh"
                                    className={styleGrids.grid}
                                    key={imageIndex}
                                    onClick={() => {
                                      handleLayer(
                                        d,
                                        imageIndex,
                                        items.layerNumber
                                      );
                                    }}
                                    sx={{
                                      border: `2px solid ${campaignsSelector?.buttonColor}`,
                                    }}
                                  >
                                    {d?.trait_name === "transparent" ? (
                                      <img
                                        src={Cross}
                                        alt=""
                                        style={{ width: "6vw", height: "10vh" }}
                                        key={imageIndex}
                                      />
                                    ) : (
                                      <img
                                        src={d?.image}
                                        alt=""
                                        style={{ width: "6vw", height: "10vh" }}
                                        loading="lazy"
                                        key={imageIndex}
                                      />
                                    )}
                                  </Box>
                                ) : (
                                  <Box
                                    width="6vw"
                                    height="10vh"
                                    className={styleGrids.grid}
                                    key={imageIndex}
                                    onClick={() => {
                                      handleLayer(
                                        d,
                                        imageIndex,
                                        items.layerNumber
                                      );
                                    }}
                                  >
                                    {d?.trait_name === "transparent" ? (
                                      <img
                                        src={Cross}
                                        alt=""
                                        style={{ width: "6vw", height: "10vh" }}
                                        key={imageIndex}
                                      />
                                    ) : (
                                      <img
                                        src={d?.image}
                                        alt=""
                                        style={{ width: "6vw", height: "10vh" }}
                                        loading="lazy"
                                        key={imageIndex}
                                      />
                                    )}
                                  </Box>
                                )}
                              </Grid>
                            );
                          }
                        )
                      ) : (
                        <p>No trait images found</p>
                      )}
                    </Grid>
                  ) : (
                    <div>
                      <p>No images data available</p>
                    </div>
                  )}
                </Box>
              </TabPanel>
            </>
          );
        })
      ) : (
        <div>
          <p>No Traits Availbles</p>
        </div>
      )}
    </Box>
  );
}

export default RightGridPanel;
export interface characterObjectI {
  issuer: string;
  attributes: {
    background: string;
    hair: string;
    eyewear: string;
    mouth: string;
    clothes: string;
    head: string;
    skin: string;
    eyes: string;
  };
}
